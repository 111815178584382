import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiPostNoAuth } from "../../../../helpers/API/ApiData";
import {
  ApiGet,
  ApiDelete,
  ApiPut,
  ApiPost,
} from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { Button } from "react-bootstrap";

const initialValues = {
  firstname: "",
  lastname: "",
  desc: "",
  email: "",
  password: "",
};

export default function AddUser({ getUserData, handleFormClose }) {
  // console.log("propss",getUserData);
  const [inputValue, setinputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [CmdDesc, setCmdDesc] = useState();

  const history = useHistory();

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setinputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  let sendletter = document.getElementById("sendLetter");

  function addClass() {
    document.body.classList.add("sent");
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (inputValue && !inputValue.firstName) {
      formIsValid = false;
      errors["firstName"] = "*Please Enter firstName!";
    }
    if (inputValue && !inputValue.lastName) {
      formIsValid = false;
      errors["lastName"] = "*Please Enter lastName!";
    }
    if (inputValue && !inputValue.phone) {
      formIsValid = false;
      errors["phone"] = "*Please Enter phone!";
    }
    if (
      (inputValue && !inputValue.email) ||
      !inputValue.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Please Enter email!";
    }

    // if (inputValue && !inputValue.password) {
    //     errors["password"] = "* Please Enter Password";
    //     formIsValid = false;
    // } else if (
    //     !inputValue.password.match(
    //         /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/
    //     )
    // ) {
    //     formIsValid = false;
    //     errors["password"] =
    //         "* Please enter a password between 7 to 15 characters which contain one lowercase letter,\n one uppercase letter, one numeric digit, and one special character";
    // }

    if (inputValue && !inputValue.confirm_password) {
      errors["confirm_password"] = "* Please Enter Password";
      formIsValid = false;
    } else if (inputValue.password != inputValue.confirm_password) {
      errors["confirm_password"] = "* Password doesn't match";
      formIsValid = false;
    }
    if (inputValue && !inputValue.address) {
      formIsValid = false;
      errors["address"] = "*Please Enter address!";
    }

    if (inputValue && !inputValue.birthDate) {
      formIsValid = false;
      errors["birthDate"] = "*Please Enter birthDate!";
    }
    if (inputValue && !inputValue.zip) {
      formIsValid = false;
      errors["zip"] = "*Please Enter zip!";
    }
    if (inputValue && !inputValue.countryCode) {
      formIsValid = false;
      errors["countryCode"] = "*Please Enter Country Code!";
    }
    setErrors(errors);
    return formIsValid;
  };
  const bindInput = (value) => {
    var regex = new RegExp("^[^0-9]*$");
    var key = String.fromCharCode(
      !value.charCode ? value.which : value.charCode
    );
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };
  console.log("inputvalue",inputValue);
  const onAddUser = (e) => {
    e.preventDefault();
    if (validateForm()) {
      ApiPost("user/registration", inputValue)
        .then((res) => {
          console.log("RES--->>>", res);
          // res?.data?.payload?.userData?.token token
          // res?.data?.payload?.userData?.user user
          history.push({
            pathname: "/reports/userData",
            state: { email: res?.data?.payload?.userData?.user?.email },
          });
          setinputValue({});
          // toast.success(res?.data?.message);
          toast.success("Bruker registrere vellykket");
        })
        .catch((err) => {
          toast.error(err);

          console.log(err, "err");
        });
    }
  };
  const back = async () => {
    history.push("/reports/userData");
  };

  const formatInputValue = () => {
    if (!inputValue?.birthDate) return "";
    // return `
    //   ${new Date(inputValue?.birthDate).getDate} /
    //   ${new Date(inputValue?.birthDate).getMonth} /
    //   ${new Date(inputValue?.birthDate).getFullYear}
    // `;

    return `
    ${new Date(inputValue?.birthDate).getDate()} /
    ${new Date(inputValue?.birthDate).getMonth()} /
    ${new Date(inputValue?.birthDate).getFullYear()}
  `;
  };

  const maximumDate = {
    year:  new Date().getFullYear(),
    month: new Date().getMonth()+1,
    day: new Date().getDate(),
  };

  const contactUS = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("input userdata", inputValue);
      ApiPost("user/registration", inputValue)
        .then((res) => {
          console.log("RES--->>>", res);
          setinputValue({});
          toast.success(res?.data?.message);
          getUserData();
          handleFormClose();
        })
        .catch((err) => {
          toast.error(err);

          console.log(err, "err");
        });
    }
  };

  return (
    <>
      <section className="children-page-align-contact">
        <div className="container">
          <div className="contact-box-alignment-screen-center">
            <div className="contact-fix-box">
              <div className="contact-title">
                {/* <h1>Melde deg på</h1> */}
                <div className="d-flex justify-content-between">
                  <h1>Legg til bruker</h1>
                  <Button variant="outline-secondary" onClick={() => back()}>
                    Tilbake
                  </Button>
                  {/* <Button variant="outline-secondary" onClick={() =>onaddUserdata()}>Add user</Button> */}
                </div>
                <div className="letter">
                  <div className="side">
                    <form onSubmit={(e) => onAddUser(e)}>
                      <div className="form-group">
                        <label>Fornavn</label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          value={inputValue.firstName}
                          name="firstName"
                          placeholder="Jenna"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>
                          {errors["firstName"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Etternavn</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          value={inputValue.lastName}
                          name="lastName"
                          placeholder="Davis"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>
                          {errors["lastName"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>E-post</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={inputValue.email}
                          name="email"
                          placeholder="jennadavis@gmail.com"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>{errors["email"]}</span>
                      </div>
                      <div className="form-group">
                          <label>Bursdag</label>
                          {/* <input placeholder="01/11/1900"></input> */}
                          <DatePicker
                            value={
                              inputValue?.birthDate
                                ? {
                                    day: new Date(
                                      inputValue?.birthDate
                                    ).getDate(),
                                    month: new Date(
                                      inputValue?.birthDate
                                    ).getMonth(),
                                    year: new Date(
                                      inputValue?.birthDate
                                    ).getFullYear(),
                                  }
                                : null
                            }
                            onChange={(date) => {
                              let newDate = new Date();
                              newDate.setDate(date?.day);
                              newDate.setMonth(date?.month);
                              newDate.setFullYear(date?.year);
                              console.log("date", date);
                              console.log("date", newDate);
                              setErrors({ ...errors, birthDate: "" });
                              setinputValue({
                                ...inputValue,
                                birthDate: newDate,
                              });
                            }}
                            inputPlaceholder="Select a date"
                            formatInputText={formatInputValue}
                            // maximumDate={{
                            //   year: new Date().getFullYear(),
                            //   day: new Date().getDate(),
                            //   month: new Date().getMonth(),
                            // }}
                            maximumDate={maximumDate}
                            inputClassName="my-custom-input"
                            shouldHighlightWeekends
                          />

                          <span style={{ color: "red" }}>
                            {errors["birthDate"]}
                          </span>
                        </div>
                      <div className="form-group">
                        <label>Landskode</label>
                        <input
                          type="text"
                          onKeyPress={bindInput}
                          className="form-control"
                          id="countryCode"
                          value={inputValue.countryCode}
                          name="countryCode"
                          placeholder="Skriv inn landskode"
                          maxLength={2}
                          // required
                          onChange={(e) => handleOnChnage(e)}
                          // onInput={(e) => {
                          //     e.target.value = Math.max(
                          //         0,
                          //         parseInt(e.target.value)
                          //     )
                          //         .toString()
                          //         .slice(0, 3);
                          // }}
                        />
                        <span style={{ color: "red" }}>
                          {errors["countryCode"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Telefon</label>
                        <input
                          type="text"
                          onKeyPress={bindInput}
                          className="form-control"
                          id="phone"
                          value={inputValue.phone}
                          name="phone"
                          placeholder="Skriv inn nummeret"
                          maxLength={10}
                          // required
                          onChange={(e) => handleOnChnage(e)}
                          // onInput={(e) => {
                          //     e.target.value = Math.max(
                          //         0,
                          //         parseInt(e.target.value)
                          //     )
                          //         .toString()
                          //         .slice(0, 10);
                          // }}
                        />
                        <span style={{ color: "red" }}>{errors["phone"]}</span>
                      </div>
                      <div className="form-group">
                        <label>Passord</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={inputValue.password}
                          name="password"
                          placeholder="********"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>
                          {errors["password"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Bekreft passord</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirm_password"
                          value={inputValue.confirm_password}
                          name="confirm_password"
                          placeholder="********"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>
                          {errors["confirm_password"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Adresse</label>
                        <textarea
                          // required
                          rows="4"
                          cols="50"
                          id="address"
                          name="address"
                          value={inputValue.address}
                          className="form-control"
                          onChange={(e) => handleOnChnage(e)}
                        ></textarea>
                        <span style={{ color: "red" }}>
                          {errors["address"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          value={inputValue.city}
                          name="city"
                          placeholder="By"
                          // required
                          onChange={(e) => handleOnChnage(e)}
                        />
                        <span style={{ color: "red" }}>{errors["City"]}</span>
                      </div>
                      <div className="form-group">
                        <label>Post kode</label>
                        <input
                          type="text"
                          onKeyPress={bindInput}
                          className="form-control"
                          id="zip"
                          value={inputValue.zip}
                          name="zip"
                          placeholder="395007"
                          maxLength={6}
                          // required
                          onChange={(e) => handleOnChnage(e)}
                          // onInput={(e) => {
                          //     e.target.value = Math.max(
                          //         0,
                          //         parseInt(e.target.value)
                          //     )
                          //         .toString()
                          //         .slice(0, 6);
                          // }}
                        />
                        <span style={{ color: "red" }}>{errors["zip"]}</span>
                      </div>
                      <div className="form-group">
                        <label>User role:</label>
                        <div className="form-control">
                          <input
                            type="radio"
                            checked={inputValue.roles === "admin"}
                            name="roles"
                            value="admin"
                            onChange={(e) => handleOnChnage(e)}
                          />
                          Admin &nbsp;&nbsp;
                          <input
                            type="radio"
                            checked={inputValue.roles === "user"}
                            name="roles"
                            value="user"
                            onChange={(e) => handleOnChnage(e)}
                          />
                          User
                        </div>
                        <span style={{ color: "red" }}>
                          {errors["address"]}
                        </span>
                      </div>
                      <div className="submit-button-center-contact">
                        <button className="">Sende inn</button>
                        <br></br>
                        <br></br>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="envelope front"></div>
                <div className="envelope back"></div>
              </div>
              {/* <p class="result-message centered">Thank you for your message</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
