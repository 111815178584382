import React, { useEffect, useState } from "react";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { getUserInfo } from "../../../../utils/user.util";
import DataTable, { defaultThemes } from "react-data-table-component";
import Category from "../../../components/Category/Category";
import User from "../../../components/User/User";
import Vendor from "../../../components/Vendor/Vendor";
import ProductReport from "../../../components/Reports/ProductReport";
// import DownloadedMusic from "../../../components/Reports/DownloadedMusic";
// import PurchasedMusic from "../../../components/Reports/PurchasedMusic";
import UploadMusic from "../../../components/Music/UploadMusic";
import Product from "../../../components/Product/Product";
// import PurchasedProduct from "../../../components/Reports/PurchasedProduct";
// import ArtistsReportsForAffiliates from "../../../components/Reports/ArtistsReportsForAffiliates";
// import Users from "../../../components/Users/Users";
import {BarChart , CartesianGrid ,XAxis ,YAxis,Tooltip,Legend, Bar} from 'recharts';

export function MixedWidget1({ className }){
  let userInfo = getUserInfo();

  const [artistData, setArtistData] = useState();
  const [adminData, setAdminData] = useState();
  const [selectedTable, setSelectedTable] = useState("Users");
  const [selectedArtistTable, setArtistSelectedTable] = useState("Music");
  const [affiliatesData, setAffiliatesData] = useState();
  const [artistAffiliateData, setArtistAffiliatesData] = useState();
  const [alluser, setAllUser] = useState();
  const [userReport, setUserReport] = useState();
  const [planlist, setPlanList] = useState();
  const [chatuser, setChatUser]=useState();

  const getUserData = async () => {
    await ApiGet("user")
      .then((res) => {
        console.log("userdata......", res.data.payload.allUser.length);
        setAllUser(res.data.payload.allUser.length);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserReports = async () => {
    await ApiGet("cms/contact")
      .then((res) => {
        console.log("admin contact", res);
        setUserReport(res.data.payload.length);
        console.log("contact..", res.data.payload.length);
        // setFilteredReport(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getChatsUser = async () => {
    await ApiGet("chat/get-all-chat")
      .then((res) => {
        console.log("chat user", res?.data?.payload?.allconversition?.length);
        setChatUser(res?.data?.payload?.allconversition?.length);
        console.log("contact..", res.data.payload.length);
        // setFilteredReport(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPlanDetails = async () => {
    await ApiGet("user/subscriber")
      .then((res) => {
        console.log("Plans", res.data.payload.subscriberCount);
        setPlanList(res.data.payload.subscriberCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUserData();
    getUserReports();
    getPlanDetails();
    getChatsUser();
  }, []);

  

  const data = [
    {
      "name": "Bruker",
      "uv": alluser
    },
    {
      "name": "abonnenter",
      "uv": planlist
    },
    {
      "name": "chatte",
      "uv": chatuser
    }
  ]

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-white py-5">
        <div className="card-body p-0 position-relative overflow-hidden">
          {/* Stat */}
          <div
            // id="kt_mixed_widget_1_chart"
            className="card-rounded-bottom bg-white"
            style={{ height: "120px" }}
          >
            <h1>Dashbord</h1>
            <h3 className="card-title font-weight-bolder text-white"></h3>
          </div>

          <>

            <div className="card-spacer mt-n25">
              <div className="row m-5">
                <div className="col bg-light-success px-6 py-8 rounded-xl">
                  {/*  user */}
                  Bruker : {alluser}
                </div>&nbsp;
                <div className="col bg-light-success px-6 py-8 rounded-xl">
                  {/* subscribers */}
                  abonnenter : {planlist}
                </div>&nbsp;
                <div className="col bg-light-success px-6 py-8 rounded-xl">
                  {/* Contact us */}
                  chatte : {chatuser}
                </div>
              </div>
            </div>

            <>
              <BarChart width={830} height={250} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>

            </>

          </>

          <>
            <div className="card-spacer mt-n25">
              <div className="row m-0"></div>
            </div>
            <div className="my-5 mx-10">
              {/* <ArtistsReportsForAffiliates /> */}
            </div>
          </>

          <> </>

          <div className="resize-triggers">
            <div className="expand-trigger">
              <div style={{ width: "411px", height: "461px" }} />
            </div>
            <div className="contract-trigger" />
          </div>
        </div>
      </div>
    </div>
  );
}

