import React, { useEffect, useState } from "react";
import {
  ApiGet,
  ApiPost,
  ApiDelete,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UploadMusic = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    await ApiGet("artist/checkStatus")
      .then((res) => {
        if (res.data.data.msg) {
          setOpen(true);
          setUpdateMusic(true);
          setSubmitValue(false);
        } else {
          setShow({
            open: true,
            err: "Music",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const [allGenre, setAllGenre] = useState([]);
  const [allMusic, setAllMusic] = useState([]);
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [mid, setMId] = useState();
  const [amount, setAmount] = useState(0);
  const [genreId, setGenreId] = useState();
  const [genreTitle, setGenreTitle] = useState();
  const [isPaid, setIsPaid] = useState(false);
  // const [isPaidForUpdate, setIsPaidForUpdate] = useState();
  const [country, setCountry] = useState();
  const [musicThumbnail, setMusicThumbnail] = useState();
  const [musicFile, setMusicFile] = useState();
  const [errors, setErrors] = useState([]);
  const [updateMusic, setUpdateMusic] = useState(false);
  const [submitValue, setSubmitValue] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [audioDuration, setAudioDuration] = useState();

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState({
    id: undefined,
    open: false,
    err: undefined,
  });

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
    setMId(id);
  };

  useEffect(() => {
    getGenreData();
    getMusicData();
  }, [dataChange]);

  const getGenreData = async () => {
    await ApiGet("genre/getAllGenre")
      .then((res) => {
        //console.log('data----------------------',res.data)
        setAllGenre(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getMusicData = async () => {
    await ApiGet("music/getAllMusic")
      .then((res) => {
        console.log("data----------------------", res.data.data);
        setAllMusic(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!title) {
      formIsValid = false;
      errors["title"] = "Please enter title";
    }
    if (!desc) {
      formIsValid = false;
      errors["desc"] = "Please enter description";
    }
    if (isPaid === null) {
      formIsValid = false;
      errors["isPaid"] = "Please select music pricing";
    }
    if (!country) {
      formIsValid = false;
      errors["country"] = "Please select country";
    }
    // if (!genreId) {
    //   formIsValid = false;
    //   errors["genre"] = "Please select genre";
    // }
    // if (!musicFile) {
    //   formIsValid = false;
    //   errors["musicFile"] = "Please select your music file";
    // }
    // if (!musicThumbnail) {
    //   formIsValid = false;
    //   errors["musicThumbnail"] = "Please select your music thumbnail";
    // }

    setErrors(errors);
    return formIsValid;
  };
  const submitMusic = async () => {
    setLoading(true);
    if (validateForm()) {
      const formData = new FormData();
      formData.append("musicTitle", title);
      formData.append("musicDesc", desc);
      formData.append("isPaid", isPaid);
      formData.append("amount", isPaid ? amount : 0);
      formData.append("musicImage", musicThumbnail);
      formData.append("genre", genreId ? genreId : "60814f76d2d9c626f87ca6df");
      formData.append("country", country);
      formData.append("duration", audioDuration);
      formData.append("musicUrl", musicFile);
      console.log(formData);
      setLoading(true);
      await ApiPost("music/submitMusic", formData, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          setOpen(false);
          setDataChange([...dataChange, "Music Added"]);
          setLoading(false);
          setUpdateMusic(false);
          console.log(res);
          setTitle();
          setDesc();
          setAmount();
          setIsPaid();
          setMusicThumbnail();
          setMusicFile();
          setCountry();
          setGenreId();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const handleForUpdateMusic = async (id) => {
    setOpen(true);
    setSubmitValue(true);
    const data = {
      id: id,
    };
    await ApiPost("music/getMusicById", data)
      .then((res) => {
        console.log(res.data.data);
        //setAllMusic(res.data.data);
        setTitle(res.data.data[0].title);
        setDesc(res.data.data[0].description);
        setIsPaid(res.data.data[0].isPaid);
        setAmount(res.data.data[0].amount);
        setCountry(res.data.data[0].country);
        setGenreTitle(res.data.data[0].genreData.genreTitle);
        setGenreId(res.data.data[0].genreData._id);
        setAudioDuration(res.data.data[0].duration.$numberDecimal);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleUpdateMusic = async () => {
    console.log("mid", mid);
    console.log("title", title);
    console.log("desc", desc);
    console.log("isPaid", isPaid);
    console.log("amount", isPaid ? amount : 0);
    console.log("MTHUBNAIL", musicThumbnail);
    console.log("GENEreid", genreId);
    console.log("contry", country);
    console.log("music file", musicFile);
    setLoading(true);
    if (validateForm()) {
      console.log(genreId);
      const formData = new FormData();
      formData.append("id", mid);
      formData.append("musicTitle", title);
      formData.append("musicDesc", desc);
      formData.append("isPaid", isPaid);
      formData.append("amount", isPaid ? amount : 0);
      formData.append("musicImage", musicThumbnail);
      formData.append("genre", genreId ? genreId : "60814f76d2d9c626f87ca6df");
      formData.append("country", country);
      formData.append("duration", audioDuration);
      //formData.append("musicUrl", musicFile);

      // console.log("musicFile", musicFile);
      // console.log("musicimage", musicThumbnail);
      // for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      console.log(formData);
      await ApiPut("music/update", formData, {
        "Content-Type": "multipart/form-data",
      })
        .then((res) => {
          setOpen(false);
          setUpdateMusic(false);
          setDataChange([...dataChange, "Music Updated"]);
          setLoading(false);
          console.log(res);
          setTitle();
          setDesc();
          setAmount();
          setIsPaid();
          setMusicThumbnail();
          setMusicFile();
          setCountry();
          setGenreId();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const handleDeleteMusic = async (id) => {
    const data = {
      id: mid,
    };
    await ApiDelete("music/delete", data)
      .then((res) => {
        handleClose();
        setDataChange([...dataChange, "Music delete"]);
      })
      .catch((err) => {
        console.log("err");
      });
  };

  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
    },
    {
      name: "Country",
      selector: "country",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
    },
    {
      name: "Views",
      selector: "views",
      sortable: true,
    },
    {
      name: "Purchases",
      selector: "purchases",
      sortable: true,
    },
    {
      name: "Downloads",
      selector: "downloads",
      sortable: true,
    },
    {
      name: "Date",
      cell: (row) => {
        return <>{getNewDate(row.created)}</>;
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  setShow({
                    id: row._id,
                    open: true,
                  });
                }}
              >
                <DeleteIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setUpdateMusic(true);
                  setMId(row._id);
                  handleForUpdateMusic(row._id);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const getNewDate = (date) => {
    var d = new Date(date);
    var month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var newDate = `${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    return newDate;
  };

  const handleMusicUpload = (e) => {
    setMusicFile(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function(event) {
      var audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContext.decodeAudioData(event.target.result, function(buffer) {
        var duration = buffer.duration;
        setAudioDuration(duration);
      });
    };
    reader.onerror = function(event) {
      console.error("An error ocurred reading the file: ", event);
    };
    reader.readAsArrayBuffer(file);
  };
  console.log("audioDuration", audioDuration);

  return (
    <>
      <div className="card p-5">
        <div className="p-4 mb-20">
          <div className="d-flex justify-content-between">
            <h1>All Music</h1>
            <div>
              <Button
                className="bg-primary"
                onClick={() => {
                  handleClickOpen();
                }}
              >
                Add New Music
              </Button>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={allMusic}
            customStyles={customStyles}
          />
        </div>
      </div>
      <Modal
        show={show.open}
        onHide={() => {
          setShow({
            open: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show.err
            ? `${show.err} upload Limit Exceeded. Please purchase subscription plan.`
            : "Are you sure want to delete the music?"}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShow({
                open: false,
              });
            }}
          >
            Close
          </Button>
          {show.err ? (
            <Button variant="success">Purchase</Button>
          ) : (
            <Button variant="danger">Yes</Button>
          )}
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateMusic && updateMusic === true ? (
            <div className="form ml-30 ">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="musictitle"
                      value={title && title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      className={`form-control form-control-lg form-control-solid `}
                      name="musicdescription"
                      value={desc && desc}
                      onChange={(e) => {
                        setDesc(e.target.value);
                      }}
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["desc"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music Genre
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid `}
                    name="genre"
                    onChange={(e) => {
                      setGenreId(e.target.value);
                    }}
                  >
                    <option>Select Genre...</option>

                    {allGenre?.map((rec, key) => {
                      return (
                        <>
                          {genreTitle && genreTitle !== "" ? (
                            rec.genreTitle === genreTitle ? (
                              <option key={key} selected value={rec._id}>
                                {rec.genreTitle}
                              </option>
                            ) : (
                              <option key={key} value={rec._id}>
                                {rec.genreTitle}
                              </option>
                            )
                          ) : (
                            <option key={key} value={rec._id}>
                              {rec.genreTitle}
                            </option>
                          )}
                        </>
                      );
                    })}
                  </select>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {/* {errors["genre"]} */}
                  </span>
                </div>
              </div>
              <div className="form-group row ">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Pricing
                </label>
                <div className="col-lg-9 col-xl-6 pt-2">
                  {isPaid === false ? (
                    <div className="radio-inline">
                      <label className="radio">
                        <input
                          type="radio"
                          name="ispaid"
                          value="free"
                          onChange={() => setIsPaid(false)}
                          checked
                        />
                        <span></span>Free
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="ispaid"
                          value="paid"
                          onChange={() => setIsPaid(true)}
                        />
                        <span></span>Paid
                      </label>
                    </div>
                  ) : (
                    <div className="radio-inline">
                      <label className="radio">
                        <input
                          type="radio"
                          name="ispaid"
                          value="free"
                          onChange={() => setIsPaid(false)}
                        />
                        <span></span>Free
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="ispaid"
                          value="paid"
                          onChange={() => setIsPaid(true)}
                          checked
                        />
                        <span></span>Paid
                      </label>
                    </div>
                  )}
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["isPaid"]}
                  </span>
                </div>
              </div>
              {isPaid ? (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Music Amount
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="number"
                        className={`form-control form-control-lg form-control-solid `}
                        name="musicamount"
                        value={amount && amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music Country
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid `}
                    name="country"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    <option>Select Country...</option>
                    {country === "India" ? (
                      <>
                        <option selected>India</option>
                        <option>Canada</option> <option>Spain</option>
                      </>
                    ) : country === "Canada" ? (
                      <>
                        <option>India</option>
                        <option selected>
                          Canada
                        </option> <option>Spain</option>{" "}
                      </>
                    ) : country === "Spain" ? (
                      <>
                        <option>India</option>
                        <option>Canada</option> <option selected>Spain</option>
                      </>
                    ) : (
                      <>
                        <option>India</option>
                        <option>Canada</option> <option>Spain</option>
                      </>
                    )}
                  </select>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["country"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music Thumbnail
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="file"
                      // className={`form-control form-control-lg form-control-solid `}
                      //value={}
                      name="musicthumbnail"
                      onChange={(e) => {
                        setMusicThumbnail(e.target.files[0]);
                      }}
                      accept="image/*"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["musicThumbnail"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Music File
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      id="fileinput"
                      type="file"
                      // className={`form-control form-control-lg form-control-solid `}
                      name="musicfile"
                      onChange={(e) => {
                        handleMusicUpload(e);
                      }}
                      accept="audio/*"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["musicFile"]}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={() => {
                      handleUpdateMusic();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span> Update Music</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      submitMusic(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Submit Music</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default UploadMusic;
