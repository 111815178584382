import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet, ApiDelete, ApiPut } from "../../../helpers/API/ApiData";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../../../utils/user.util"
import { Modal } from "react-bootstrap";
// import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {BarChart , CartesianGrid ,XAxis ,YAxis,Legend, Bar} from 'recharts';

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Profile = () => {
    let userInfo = getUserInfo();
    console.log("userInfo", userInfo.currentPlan.planDescription);
  const classes = useStyles();
  const [userReport, setUserReport] = useState();
  const [errors] = useState([]);
  const [alluser, setAllUser] = useState([]);
  const [user, setUser]=useState([])

  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    await ApiGet("user")
      .then((res) => {
        console.log("userdata_admi...", res.data.payload.allUser[0]);
        console.log("admin name", res.data.payload.allUser.firstName);
        setAllUser((res.data.payload.allUser[0]));
        setUserReport(res.data.payload.allUser)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let i = 0;
  
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  return (
    <>
      <div className={`card card-custom bg-gray-100 `}>
      {/* Header */}
      <div className="card-header border-0 bg-white py-5">
        <div className="card-body p-0 position-relative overflow-hidden">
          {/* Stat */}
          <div
            // id="kt_mixed_widget_1_chart"
            className="card-rounded-bottom bg-white"
            style={{ height: "120px" }}
          >
            <h1>Profil</h1>
            <h3 className="card-title font-weight-bolder text-white"></h3>
          </div>

          <>

            <div className="card-spacer mt-n25">
              <div className="row m-5">
                <div className="col bg-light-success px-6 py-8 rounded-xl">
                  {/*  user */}
                  {/* <h3>Id :  {alluser._id}</h3> */}
                  <h2> {userInfo.firstName} {userInfo.lastName}</h2><br></br>
                  {/* <h3>Lastname : {userInfo.lastName}</h3> */}
                  <h5>Mail id : {userInfo.email}</h5>
                  <h5>Plan Description :  {userInfo.currentPlan.planDescription}</h5>
                </div>
              </div>
            </div>
          </>

          <div className="resize-triggers">
            <div className="expand-trigger">
              <div style={{ width: "411px", height: "461px" }} />
            </div>
            <div className="contract-trigger" />
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default Profile;
