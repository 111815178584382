import React from "react";
import { Demo1Dashboard } from "./Demo1Dashboard";
export function Dashboard() {
  return (
    <>
      <div >
        <Demo1Dashboard />
      </div>
    </>
  );
}
