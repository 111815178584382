import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";

import DashboardPage from "./pages/DashboardPage";
import Artists from "../_metronic/components/Artists/Artists";
import ArtistsReports from "../_metronic/components/Reports/ArtistsReports";
import UsersReports from "../_metronic/components/User/User";
import Users from "../_metronic/components/Users/Users";
import Vendors from "../_metronic/components/Vendor/Vendor";
import Category from "../_metronic/components/Category/Category";
import Plan from "../_metronic/components/Subscription/Plan";
import UserData from "../_metronic/components/Reports/UserData/UserData";
import AddUser from "./modules/Auth/pages/AddUser";
import Profile from "../_metronic/components/Reports/Profile";
import Chat from "../_metronic/components/Users/Chat";

export default function BasePage() {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          <ContentRoute exact path="/dashboard" component={DashboardPage} />
          <ContentRoute exact path="/artists" component={Artists} />
          <ContentRoute exact path="/users" component={Users} />
          <ContentRoute exact path="/plans" component={Plan} />
          <ContentRoute exact path="/vendors" component={Vendors} />
          <ContentRoute exact path="/category" component={Category} />
          <ContentRoute exact path="/reports/users" component={UsersReports} />
          <ContentRoute exact path="/reports/userData" component={UserData} />
          <ContentRoute exact path="/chat" component={Chat} />
          <ContentRoute exact path="/Auth/adduser" component={AddUser} />
          <ContentRoute exact path="/reports/profile" component={Profile} />
          <ContentRoute exact path="/reports/artists" component={ArtistsReports}/>
          {/* <ContentRoute exact path="/uploadmusic" component={UploadMusic} /> */}

          <Redirect to="error/error-v6" />
        </Switch>
      </Suspense>
    </>
  );
}
