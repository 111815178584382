import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { ApiPostNoAuth } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
// import { FormattedMessage } from "react-intl";
// import { Link } from "react-router-dom";

toast.configure();

const initialValues = {
  email: "",
  password: "",
  role: "",
};

export default function Login() {
  const history = useHistory();
  // const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is Required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is Required"),
    //role: Yup.string().required("Role is Required."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const data = {
        email: values.email,
        password: values.password,
      };
      const role = values.role;
      console.log("DATA", data);
      await ApiPostNoAuth("user/login", data)
        .then((res) => {
          console.log("hook", res.data);
          try {
            console.log(res);
            // if (parseInt(res.status / 100) === 2) {
            console.log("hello if");
            localStorage.clear();
            console.log("redatatarts", res);
            authUtil.setToken(res.data.payload.user.token);
            userUtil.setUserInfo(res.data.payload.user);

            console.log("vijayvijay", res.data.message);
            setLoading(false);
            setSubmitting(false);
            toast.success(res.data.message);
            window.location.reload();

            // setLoading(false);
            // setSubmitting(false);
            // setStatus("Login Credentials are incorrect.");
            // toast.success(res.data.message);
            // console.log("vijayvijay", res.data.message);
          } catch (err) {
            console.log("hello err");
            // console.log("Error : ", err);
            console.log("vijayvijay", res.data.message);
            setLoading(false);
            setSubmitting(false);
            setStatus("Error connecting to network.");
          }
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
          console.log(err.response.data.message);
          toast.error(err.response.data.message);

          console.log("vijay");
        });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Login Account
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* <div className="form-group fv-plugins-icon-container">
          <select
            className={`form-control form-control-lg form-control-solid ${getInputClasses(
              "role"
            )}`}
            name="role"
            {...formik.getFieldProps("role")}
          >
            <option>Select Role...</option>
            <option value="Admin">Admin </option>
            <option value="Artist">Artist</option>
          </select>
          {formik.touched.role && formik.errors.role ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.role}</div>
            </div>
          ) : null}
        </div> */}
        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */} forgot
            password?
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}
