import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyAhIs-NcQBkxz6UvFxdLOuE7-fkPbhTC2s",
    //   authDomain: "your credentials",
      projectId: "svador-49f08",
      storageBucket: "svador-49f08.appspot.com",
      messagingSenderId: "984237866323",
      appId: "1:984237866323:android:f35d705b935bccadba35a6"
  };
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;