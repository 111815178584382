import React, { useEffect, useRef, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";

import socketIOClient from "socket.io-client";
import { getUserInfo } from "../../../utils/user.util";

const SOCKET_SERVER_URL = "https://api.lifesummary.no/";
const NEW_CHAT_MESSAGE_EVENT = "new-message";

const Chat = () => {
  let userInfo = getUserInfo();
  const [allUserData, setAllUserData] = useState([]);
  const [roomId, setRoomId] = useState();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    console.log("userInfo", userInfo);
    getUserData();
  }, []);

  const socketRef = useRef();
  useEffect(() => {
    console.log("roomIdIn", roomId);
    socketRef.current = socketIOClient(SOCKET_SERVER_URL);
    console.log("SOCKET_SERVER_URL", SOCKET_SERVER_URL);

    socketRef.current.emit("connection", { roomId: roomId });
    socketRef.current.emit("join", { roomId: roomId });
    socketRef.current.on("history", function(event) {
      console.log("history", event);
      if (event?.chats && event?.chats?.length > 0) {
        setMessages(event?.chats);
      } else {
        setMessages([]);
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [roomId]);

  const getUserData = async () => {
    await ApiGet("chat/get-all-chat")
      .then((res) => {
        console.log("User chat", res.data.payload.allconversition);
        setAllUserData(res.data.payload.allconversition);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //   const getChatData = async (id) => {
  //     await ApiPost(`chat/get-history`, { roomId: id })
  //       .then((res) => {
  //         console.log("Individual Chat", res.data.payload);
  //         // setAllUserData(res.data.payload.allconversition);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   };

  const setHandleChange = (e) => {
    setInputValue(e?.target?.value);
  };

  const sendMessage = () => {
    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, function(event) {
      console.log("event", messages, event);
      messages.push(event);
      setMessages([...messages]);
      setInputValue("");
    });
    socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
      roomId: roomId,
      message: inputValue,
      sender: userInfo?.email,
    });
  };

  return (
    <div className="card container p-5 chat-modal-body-height">
      <div className="new-chat-grid">
        <div className="new-chat-grid-items">
          {allUserData.map((item, index) => {
            return (
              <div
                className="chat-profile-name-style"
                onClick={() => {
                  // console.log("item", item);
                  setRoomId(item?._id);
                }}
              >
                <p>{item?.participateIds.join(" , ")}</p>
              </div>
            );
          })}
        </div>
        <div className="new-chat-grid-items">
          <div className="chat-view-show">
            {messages?.length > 0 &&
              messages?.map((message, index) => {
                return message.sender == userInfo.email ? (
                  <div className="main-message-box ta-right">
                    <div className="message-dt">
                      <div className="message-inner-dt">
                        <div>
                          <p className="message-chat">{message?.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="main-message-box st3">
                    <div className="message-dt st3">
                      <div className="message-inner-dt">
                        <div>
                          <p className="message-chat">{message?.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {roomId && (
            <div className="chat-message-send-grid">
              <div className="chat-message-send-grid-items">
                <input
                  type="text"
                  name="message"
                  value={inputValue ? inputValue : ""}
                  onChange={setHandleChange}
                />
              </div>
              <div className="chat-message-send-grid-items">
                <button onClick={() => sendMessage()}>Send</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
