import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import storage from "../firbase/Firbase";
import DataTable, { defaultThemes } from "react-data-table-component";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
// import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { functionalUpdate } from "react-table";
import { toast } from "react-toastify";

import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { set } from "date-fns";
import draftToHtml from "draftjs-to-html";

toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Category = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [allGenre, setAllGenre] = useState([]);
  const [allGenrels, setallGenrels] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [gid, setGid] = useState();

  const [submitValue, setSubmitValue] = useState(false);

  const [genreName, setGenreName] = useState();

  const [genreImage, setGenreImage] = useState();

  const [errors] = useState([]);
  const [errorImages, seterrorImages] = useState();

  const [show, setShow] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [genreMusic, setGenreMusic] = useState();
  const [Link, setLink] = useState();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleClickOpen = () => {
    setGenreName("");
    setOpen(true);
    setSubmitValue(false);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
    setGid(id);
  };
  const handleForMore = async (genreId) => {
    setOpenList(true);
    const data = { genreId: genreId };
    await ApiPost("music/getMusicByGenre", data)
      .then((res) => {
        setGenreMusic(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getProductData();
  }, [dataChange]);
  const handleCloseMusicPage = () => {
    setOpenList(false);
    setGenreMusic([]);
  };
  const getProductData = async () => {
    await ApiGet("admin/product-data")
      .then((res) => {
        console.log("new", res);
        setAllGenre(res.data.payload.allProductData[0]);
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(
                res?.data?.payload?.allProductData[0]?.productContent
              )
            )
          )
        );
        setGenreImage(res.data.payload.allProductData[0].image);
        setLink(res.data.payload.allProductData[0].downloadLink);
        setGid(res.data.payload.allProductData[0]._id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleDeleteProduct = async () => {
    const data = {
      id: gid,
    };
    console.log("id", data);
    await ApiDelete(`category/${gid} `)
      .then((res) => {
        handleClose();
        setGid("");
        setDataChange([...dataChange, "genre delete"]);
        toast.success(res.data.message);
        console.log("datachnage", ...dataChange);
        //console.log(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err");
      });
  };

  console.log("genreImage", genreImage);
  // const finalpost = async () => {
  //   // const data = {
  //   //   name: genreName,
  //   //   imagePath: url,
  //   // };

  //   const Data = new FormData();
  //   Data.append("productName", genreName);
  //   Data.append("image", genreImage);

  //   await ApiPost("admin/product-data", Data)
  //     .then((res) => {
  //       setLoading(false);
  //       setGenreName("");

  //       handleClose1();
  //       toast.success(res.data.message);
  //       setDataChange([...dataChange, "genre add"]);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast.error(err.response.data.message);
  //       console.log(err);
  //     });
  // };

  const handleAddGenre = async () => {
    setOpen(true);
    setLoading(true);
    if (genreImage == null) return;
    // storage.ref(`/images/${genreImage.name}`).put(genreImage);
    console.log("......", genreImage[0]);
    const ref = storage.ref(`/images/${genreImage[0].name}`);
    const uploadTask = ref.put(genreImage[0]);
    uploadTask.on("state_changed", console.log, console.error, () => {
      ref.getDownloadURL().then((url) => {
        setURL(url);
      });
    });

    console.log();
  };
  const handleForUpdateGenre = async (data) => {
    // const markup = convertFromHTML(allGenre[0]?.productContent);
    // const rawContentState = convertFromRaw(markup);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(allGenre[0]?.productContent)
        )
      )
    );
    setLink(allGenre[0]?.downloadLink);
    setOpen(true);
    setSubmitValue(true);
    console.log(data);
    setGenreName(data.productContent);

    console.log(
      "Product ID///////////////////////////////////",
      data.productContent
    );
    setGid(data._id);
  };

  const [url, setURL] = useState();

  // useEffect(() => {
  //   if (submitValue && (url !== null || url !== undefined || url !== "")) {
  //     finalUpdate();
  //   } else if (
  //     !submitValue &&
  //     (url !== null || url !== undefined || url !== "")
  //   ) {
  //     finalpost();
  //   }
  // }, [url]);

  const finalUpdate = async () => {
    const Data = new FormData();
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    console.log("markup", markup);
    Data.append(
      "productContent",
      markup
      // convertFromRaw(editorState.getCurrentContent())
    );
    Data.append("image", genreImage);
    Data.append("downloadLink", Link);

    console.log("image", genreImage);
    console.log("downloadLink", Link);
    await ApiPut(`admin/product-data/id=${gid}`, Data)
      .then((res) => {
        setOpen(false);
        // setGenreName(editorState);

        setDataChange([...dataChange, "Genre Updated"]);
        toast.success(res.data.message);

        setLoading(false);

        //console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };
  const handleUpdateGenre = async () => {
    setLoading(true);

    const data = {
      id: gid,
    };

    if (genreImage == null) {
      seterrorImages("please choose the images");
      setLoading(false);
      return;
    } else {
      console.log("......", genreImage[0]);
      const ref = storage.ref(`/images/${genreImage.name}`);
      const uploadTask = ref.put(genreImage);
      uploadTask.on("state_changed", console.log, console.error, () => {
        ref.getDownloadURL().then((url) => {
          setURL(url);
        });
      });
    }

    // storage.ref(`/images/${genreImage.name}`).put(genreImage);

    // console.log({ url });
  };
  let i = 0;
  const columns = [
    {
      name: "S.nr.",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },

    {
      name: "Sideinnhold",
      cell: (productContent) => {
        // const rawContentState = convertFromRaw(productContent?.productContent);
        // const markup = draftToHtml(rawContentState);
        console.log(
          "productContent?.productContent",
          productContent?.productContent
        );
        // document.getElementById("page_content_data").innerHTML =
        //   productContent?.productContent;
        return (
          <div id="page_content_data">
            {ReactHtmlParser(productContent?.productContent)}
          </div>
        );
      },
    },

    {
      name: "Bilde",
      cell: (row) => {
        return (
          <>
            <div className="p-3">
              <img src={row.image} style={{ maxWidth: "100px" }} />
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      name: "Last ned lenke",
      cell: (row) => {
        return (
          <>
            <div className="p-3">
              <a href={row.downloadLink} target="_blank">
                {row.downloadLink}
              </a>
            </div>
          </>
        );
      },
      sortable: true,
    },

    // {
    //   name: "Satus",
    //   selector: "registrationDate",
    //   sortable: true,
    // },

    // {
    //   name: "status",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="p-3">
    //           {row.status.name}
    //         </div>
    //       </>
    //     );
    //   },
    //   wrap: true,
    //   sortable: true,
    // },

    // {
    //   name: "Update date",
    //   cell: (row) => {
    //     let d = new Date(row.updated);
    //     let month = [
    //       "Jan",
    //       "Feb",
    //       "Mar",
    //       "Apr",
    //       "May",
    //       "Jun",
    //       "Jul",
    //       "Aug",
    //       "Sep",
    //       "Oct",
    //       "Nov",
    //       "Dec",
    //     ];

    //     let updatedate = ` ${
    //       month[d.getMonth()]
    //     } ${d.getDate()},${d.getFullYear()} `;

    //     return <div>{updatedate}</div>;
    //   },
    //   sortable: true,
    // },

    {
      name: "Oppdater",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {/* <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  handleShow(row._id);
                }}
              >
                <DeleteIcon />
              </div> */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleForUpdateGenre(row);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Show More",
    //   cell: (row, key) => {
    //     return (
    //       <>
    //         {row.length === 0 ? (
    //           <div>--</div>
    //         ) : (
    //           <div className="d-flex justify-content-between">
    //             <div
    //               className="cursor-pointer"
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 handleForMore(row._id);
    //               }}
    //             >
    //               <Tooltip title="Show More" arrow>
    //                 <InfoOutlinedIcon />
    //               </Tooltip>
    //             </div>
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div>
        <div className="card p-5">
          <div className="p-4 mb-20">
            <div className="d-flex justify-content-between">
              <h1>All Product</h1>
            </div>
            <List>
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {" "}
                    Innhold på landingsside
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(abcd) => setEditorState(abcd)}
                      />
                    </div>
                    {/* <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span> */}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Landing side bilde
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="file"
                        className={`form-control form-control-lg form-control-solid `}
                        name="price"
                        // value={title && title}
                        onChange={(e) => setGenreImage(e.target.files[0])}
                      />
                      <img src={allGenre.image} style={{ maxWidth: "100px" }} />
                      <div className="errorimages">{errorImages}</div>
                    </div>
                    {/* <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["Image"]}
                </span> */}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Last ned lenke
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="input"
                        className={`form-control form-control-lg form-control-solid `}
                        name="downloadLink"
                        value={allGenre.downloadLink}
                        onChange={(e) => setLink(e.target.value)}
                      />
                      {/* <image alt='image' src='allGenre.image' ></image> */}
                      <div className="errorimages">{errorImages}</div>
                    </div>
                    {/* <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["Image"]}
                </span> */}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  {/* {submitValue && submitValue === true ? ( */}
                  <button
                    onClick={(e) => {
                      finalUpdate();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Oppdater data</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                  {/* ) : (
                ""
              )} */}
                </div>
              </div>
            </List>
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
