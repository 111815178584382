const protocol = "https";
// const host = "localhost:3000/api";
// const host = "192.168.29.125:5000/api/v1";
// const host = "192.168.0.109:8000/api";
// const host = "localhost:5000/api/v1";

// const host = "localhost:5000/api/v1";
// const host = "3.135.244.200/api";
// const host = "localhost:3000/api";
const host = "api.lifesummary.no/api/v1";

const port = "";
const trailUrl = "";

const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
