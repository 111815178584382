import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
// import { Button } from "react-bootstrap";
// import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";
// import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import KeyboardVoiceIcon from "@material-ui/icons/KeyboardVoice";
import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "react-bootstrap";
// import { makeStyles } from '@material-ui/core/styles';
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";


toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Vendor = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [allGenre, setAllGenre] = useState([]);
  const [allGenrels, setallGenrels] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [gid, setGid] = useState();
  const [giddisable, setgiddisable] = useState();

  const [submitValue, setSubmitValue] = useState(false);

  const [genreName, setGenreName] = useState();
  const [genreImage, setGenreImage] = useState();

  const [errors] = useState([]);

  const [show, setShow] = useState(false);
  const [adddisable, setadddisable] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [genreMusic, setGenreMusic] = useState();
  const [activeStatusId, setIsactiveStatusId] = useState();
  const [addactive, setisaddactive] = useState();
  const [aid, setaid] = useState();

  const [Isdisable, SetIsdisable] = useState();
  const [Isblock, setIsblock] = useState();

  useEffect(() => {
    console.log("act", activeStatusId);
  }, [activeStatusId]);

  const handleClickOpen = () => {
    setGenreName("");
    setOpen(true);
    setSubmitValue(false);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setShow(false);
    setadddisable(false);
    setisaddactive(false)

  };


  const handleShow = (id) => {
    setShow(true);
    
    setGid(id);
  };

  const handledisable = (id) => {
    setadddisable(true);
    setgiddisable(id);
  };

  const newactive = (id) => {
    setisaddactive(true);
    setaid(id);
  };

  const handleForMore = async (id) => {
    setOpenList(true);
    const data = { vendorId: id };

    await ApiGet(`product?vendorId=${id}`, data)
      .then((res) => {
        setGenreMusic(res.data.payload.product);
        console.log("products", res.data.payload.product);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProductData();
  }, [dataChange]);

  const handleCloseMusicPage = () => {
    setOpenList(false);
    setGenreMusic([]);
  };
  const getProductData = async () => {
    await ApiGet("admin/get-vendors")
      .then((res) => {
        console.log("admindata", res.data.payload.vendor);
        setAllGenre(res.data.payload.vendor);
        // activeIsset(res.data.payload.vendor)
        // setallGenrels()
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const active = async (id) => {

  //   const data = {
  //     userId:id,

  //   }

  //   // console.log("dfdfd",data)

  //   console.log("datadata", data)

  //   await ApiGet(`admin/block?userId=${id}&status=${activeStatusId}`, data)
  //     .then((res) => {
  //       console.log("admin",res);

  //       // setallGenrels()
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  const handleDeleteProduct = async () => {
    const data = {
      id: gid,
    };
    await ApiDelete(data)
      .then((res) => {
        handleClose();
        setGid("");
        setDataChange([...dataChange, "genre delete"]);
        toast.success(res.data.message)
        //console.log(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err");
      });
  };
  const handleAddGenre = async () => {
    setLoading(true);
    const genreData = new FormData();
    genreData.append("genreTitle", genreName);
    genreData.append("genreImage", genreImage);

    await ApiPost()
      .then((res) => {
        setLoading(false);
        setGenreName("");
        setGenreImage("");
        handleClose1();
        setDataChange([...dataChange, "genre add"]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleForUpdateGenre = async (data) => {
    setOpen(true);
    setSubmitValue(true);
    console.log(data);
    setGenreName(data.genreTitle);
    setGid(data._id);
  };
  const handleUpdateGenre = async () => {
    setLoading(true);

    const genreData = new FormData();
    genreData.append("id", gid);
    genreData.append("genreTitle", genreName);
    genreData.append("genreImage", genreImage);
    await ApiPut()
      .then((res) => {
        setOpen(false);
        setGenreName("");
        setDataChange([...dataChange, "Genre Updated"]);
        setLoading(false);
        toast.success(res.data.message)
        //console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  // block disable active

  useEffect(() => {
    setIsactiveStatusId("active");
  }, [activeStatusId]);

  useEffect(() => {
    setIsblock("blocked");
  }, [Isblock]);

  useEffect(() => {
    SetIsdisable("disabled");
  }, [Isdisable]);

  const active = async (id) => {
    const data = {
      vendorId: aid,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiGet(`admin/block?vendorId=${aid}&status=${activeStatusId}`, data)
      .then((res) => {
        console.log("admin", res);

        // setallGenrels()
        getProductData();

        setisaddactive(false);
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      });
  };

  const Blockuser = async (id) => {
    const data = {
      vendorId: gid,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiGet(`admin/block?vendorId=${gid}&status=${Isblock}`, data)
      .then((res) => {
        console.log("admin", res);

        setGid("");
        // setallGenrels()
        getProductData();
        setShow(false);

       
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      });
  };

  const disableuser = async (id) => {
    const data = {
      vendorId: giddisable,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiGet(`admin/block?vendorId=${giddisable}&status=${Isdisable}`, data)
      .then((res) => {
        console.log("admin", res);

        // setallGenrels()
        getProductData();

        setadddisable(false);
        toast.success(res.data.message)

      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      });
  };

  //

  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },

    {
      name: "email",
      selector: "email",
      sortable: true,
    },

    {
      name: "name",
      selector: "name",
      sortable: true,
    },

    {
      name: "phone",
      selector: "phone",
      sortable: true,
    },

    {
      name: "Date",
      selector: "registrationDate",
      sortable: true,
    },

    // {
    //   name: "Satus",
    //   selector: "registrationDate",
    //   sortable: true,
    // },

    //     {
    //       name: "status",
    //       cell: (row) => {
    //         return (
    //           <>
    //             {/* <div className="p-3">
    //               {row.status.name}
    //             </div> */}
    //   <div className={classes.root}>

    //  {row.status.name === "active" ? (<button
    //        type="button"
    //        class="btn btn-primary"
    //         onClick={() => {
    //           active(row._id)
    //           setIsactiveStatusId(row.status.name)
    //         }}
    //       >
    //         Active
    //       </button>) : (

    // <button
    //           type="button" class="btn btn-danger"
    //         onClick={() => {
    //           active(row._id)
    //           setIsactiveStatusId(row.status.name)
    //         }}
    //       >
    //         ReActive
    //       </button>

    //       )

    //  }
    // </div>

    //           </>
    //         );
    //       },
    //       wrap: true,
    //       sortable: true,
    //     },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            {row.status.name !== "active" ? (
              <>
                
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      newactive(row._id);
                    }}
                  >
                    Active
                  </button>
            
              </>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="cursor-pointer mr-3">
                  <Tooltip title="Block the user" arrow>
                    <NotInterestedIcon
                      onClick={() => {
                        // Blockuser(row._id);
                        handleShow(row._id);
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="cursor-pointer">
                  <Tooltip title="disabled the user">
                    <PersonAddDisabledIcon
                      onClick={() => {
                        handledisable(row._id);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },

    {
      name: "Show More",
      cell: (row, key) => {
        return (
          <>
            {row.product?.length === 0 ? (
              <div>--</div>
            ) : (
              <div className="d-flex justify-content-between">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleForMore(row._id, row.musicPurchased, row.product);
                  }}
                >
                  <Tooltip title="Show More" arrow>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },

    // {
    //   name: "Update date",
    //   cell: (row) => {
    //     let d = new Date(row.updated);
    //     let month = [
    //       "Jan",
    //       "Feb",
    //       "Mar",
    //       "Apr",
    //       "May",
    //       "Jun",
    //       "Jul",
    //       "Aug",
    //       "Sep",
    //       "Oct",
    //       "Nov",
    //       "Dec",
    //     ];

    //     let updatedate = ` ${
    //       month[d.getMonth()]
    //     } ${d.getDate()},${d.getFullYear()} `;

    //     return <div>{updatedate}</div>;
    //   },
    //   sortable: true,
    // },

    // {
    //   name: "Show More",
    //   cell: (row, key) => {
    //     return (
    //       <>
    //         {row.length === 0 ? (
    //           <div>--</div>
    //         ) : (
    //           <div className="d-flex justify-content-between">
    //             <div
    //               className="cursor-pointer"
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 handleForMore(row._id);
    //               }}
    //             >
    //               <Tooltip title="Show More" arrow>
    //                 <InfoOutlinedIcon />
    //               </Tooltip>
    //             </div>
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  return (
    <>
      <div>
        <div className="card p-5">
          <div className="p-4 mb-20">
            <div className="d-flex justify-content-between">
              <h1>All Vendors</h1>
              <div></div>
            </div>
            <DataTable
              columns={columns}
              data={allGenre}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to Block vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => Blockuser()}>
            Block
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={adddisable} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to disable vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => disableuser()}>
            disable
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={addactive} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to Active vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => active()}>
            active
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="form ml-30 ">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Vendors Email
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    value={genreName && genreName}
                    onChange={(e) => setGenreName(e.target.value)}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Vendors Name
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    value={genreName && genreName}
                    onChange={(e) => setGenreName(e.target.value)}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Vendors Phone
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    value={genreName && genreName}
                    onChange={(e) => setGenreName(e.target.value)}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Vendors Image
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="file"
                    className={`form-control form-control-lg form-control-solid `}
                    name="price"
                    // value={title && title}
                    onChange={(e) => setGenreImage(e.target.files[0])}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["Image"]}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              {submitValue && submitValue === true ? (
                <button
                  onClick={(e) => {
                    handleUpdateGenre();
                  }}
                  className="btn btn-success mr-2"
                >
                  <span>Update Vendors</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleAddGenre();
                  }}
                  className="btn btn-success mr-2"
                >
                  <span>Add Genre</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              )}
            </div>
          </div>
        </List>
      </Dialog>

      <Dialog
        fullScreen
        open={openList}
        onClose={handleCloseMusicPage}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseMusicPage}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="row d-flex mt-5 justify-content-around m-8 bg-white">
            
              
              
          {genreMusic?.length !== 0 ? (
              genreMusic?.map((data, key) => {
                return (
                  <>
                    <div className="col-md-4 p-5 " key={key}>
                      <div className="card body pt-5 pl-8 pr-8 pb-4 shadow p-3 mb-5 bg-white rounded">
                        <div className="row row-cols-2 m-1 h3">
                          <strong>products : {data?.name}</strong>
                          <div></div>
                        </div>
                        <div className="text-center pb-5">
                          <img
                            src={
                              data?.imagePath
                                ? data?.imagePath
                                : "../media/Media-library1.svg"
                            }
                            alt="music image"
                            style={{
                              height: "140px",
                              width: "140px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div>
                          <div className="row row-cols-2 m-1">
                            <strong>Product Title</strong>
                            <div className="text-left">{data?.name}</div>
                          </div>
                          {/* <div className="row row-cols-2 m-1">
                            <strong>Artist</strong>
                            <div className="text-left">
                              {data?.artistData?.firstName}{" "}
                              {data?.artistData?.lastName}{" "}
                            </div>
                          </div> */}
                          <div className="row row-cols-2 m-1">
                            <strong>Product Description</strong>
                            <div>{data?.description}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>Price</strong>
                            <div>{data?.price ? data?.price  : "free"}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>Discount</strong>
                            <div>{data?.value ? data?.value + "%"  : `0${"%"}`}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>MRP</strong>
                            <div>{data?.priceRetailMax}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>MinimumQuantity</strong>
                            <div>{data?.minimumQuantity}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>MinimumQuantityUnit</strong>
                            <div>{data?.minimumQuantityUnit}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>Type</strong>
                            <div>{data?.type}</div>
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>Unit</strong>
                            <div>{data?.unit}</div>
                          </div>
                         
                         
                          
                          <div className="row row-cols-2 m-1">
                            <strong>Ratings</strong>

                            {data?.ratings.length !== 0 ? (<Box component="fieldset" mb={3} borderColor="transparent">
        <Rating name="disabled" value={data?.ratings.length !== 0 ? data?.ratings : "No Ratings" } disabled />
      </Box>)  : (<div>No Ratings</div>) }
                            
             
                          </div>
                          <div className="row row-cols-2 m-1">
                            <strong>unitList</strong>
                            <ul>
        {data?.unitList?.map(item => {
          return <li>{item}</li>;
        })}
      </ul>
                         
                          </div>
                                     </div>
                      </div>
                    </div>
                    <hr />
                  </>
                );
              })
            ) : (
              <div className="p-10 d-flex justify-content-center my-10 font-weigth-bold">
                <h1>No products available for this Vendors!!</h1>
              </div>
            )}
                
              
            
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default Vendor;
