import React, { useEffect, useState } from "react";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import DataTable, { defaultThemes } from "react-data-table-component";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Dropdown } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { Modal } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Product = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSubmitValue(false);
    setUpdateProduct(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const [allProduct, setAllProduct] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [pid, setPid] = useState();
  const [size, setSize] = useState();
  const [size1, setSize1] = useState([]);
  const [price, setPrice] = useState();
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [desc, setDesc] = useState();
  const [updateProduct, setUpdateProduct] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);

  const [errors] = useState([]);
  let userInfo = getUserInfo();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    setShow(true);
    setPid(id);
  };

  useEffect(() => {
    getProductData();
  }, [dataChange]);

  const getProductData = async () => {
    await ApiGet("product/getProductByArtist")
      .then((res) => {
        console.log(res.data.data);
        setAllProduct(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleDeleteProduct = async () => {
    // const data = {
    //   id: id,
    // };
    await ApiDelete(`product/${pid}`)
      .then((res) => {
        handleClose();
        setDataChange([...dataChange, "Music delete"]);
        //console.log(res.data.data);
      })
      .catch((err) => {
        console.log("err");
      });
  };
  const submitProduct = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", desc);
    formData.append("price", price);
    formData.append("size", size1);
    formData.append("type", type);
    formData.append("artistId", userInfo.user.id);

    await ApiPost("product/addproduct", formData)
      .then((res) => {
        setOpen(false);
        setUpdateProduct(false);
        setDataChange([...dataChange, "Product Added"]);

        setLoading(false);
        //console.log(res);
        setSize();
        setPrice();
        setType();
        setTitle();
        setImage();
        setDesc();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleForUpdateProduct = async (id) => {
    setOpen(true);
    setPid(id);
    setSubmitValue(true);

    await ApiGet(`product/${id}`)
      .then((res) => {
        //console.log(res.data.data);
        //setAllMusic(res.data.data);
        setTitle(res.data.data.title);
        setDesc(res.data.data.description);
        setPrice(res.data.data.price);
        setSize1(res.data.data.size);
        setType(res.data.data.type);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNewDate = (date) => {
    var d = new Date(date);
    var month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var newDate = `${month[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    return newDate;
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", desc);
    formData.append("price", price);
    formData.append("size", size1);
    formData.append("type", type);

    await ApiPut(`product/${pid}`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        setOpen(false);
        setUpdateProduct(false);
        setDataChange([...dataChange, "Music Updated"]);
        setLoading(false);
        //console.log(res);
        setTitle();
        setDesc();
        setPrice();
        setSize1();
        setType();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      wrap: true,
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
    },
    {
      name: "Update date",
      cell: (row) => {
        return <>{getNewDate(row.updatedAt)}</>;
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  handleShow(row._id);
                }}
              >
                <DeleteIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setUpdateProduct(true);

                  handleForUpdateProduct(row._id);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleSize = () => {
    setSize("");
    console.log(size);
    setSize1([...size1, size]);
  };
  console.log(size1);
  return (
    <>
      <div>
        <div className="card p-5">
          <div className="p-4 mb-20">
            <div className="d-flex justify-content-between">
              <h1>All Product</h1>
              <div>
                <Button
                  className="bg-primary"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Add New Product
                </Button>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={allProduct}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => handleDeleteProduct()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          {updateProduct && updateProduct === true ? (
            <div className="form ml-30 ">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="title"
                      value={title && title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Image
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="price"
                      // value={title && title}
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["Image"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="desc"
                      value={desc && desc}
                      onChange={(e) => {
                        setDesc(e.target.value);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["desc"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Price
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      name="price"
                      value={price && price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Size
                </label>
                <div className="col-lg-5 col-xl-5">
                  <div className="d-flex justify-content-start">
                    <input
                      className={`form-control form-control-lg form-control-solid max-w-150px mr-3`}
                      name="size"
                      value={size && size}
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      rows="4"
                      cols="50"
                    />
                    <button
                      onClick={() => {
                        handleSize();
                      }}
                      className="btn btn-success mr-2"
                    >
                      Add Size
                    </button>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className="min-h-50px"
                      >
                        Your Added Sizes
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {size1?.map((data, key) => {
                          const abc = data.split(",");

                          return abc.map((data) => {
                            return (
                              <Dropdown.Item href="#/action-1">
                                {data}
                              </Dropdown.Item>
                            );
                          });
                        })}
                        {/* <Dropdown.Item href="#/action-2">L</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">M</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["size"]}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Product Type
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      className={`form-control form-control-lg form-control-solid `}
                      name="size"
                      value={type && type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      rows="4"
                      cols="50"
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["type"]}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                {submitValue && submitValue === true ? (
                  <button
                    onClick={(e) => {
                      handleUpdateProduct();
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Update Product</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      submitProduct(e);
                    }}
                    className="btn btn-success mr-2"
                  >
                    <span>Upload Product</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
      </Dialog>
    </>
  );
};

export default Product;
