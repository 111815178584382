/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getUserInfo } from "../../../../../utils/user.util";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CategoryIcon from "@material-ui/icons/Category";
import BusinessIcon from "@material-ui/icons/Business";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Dashboard*/}
        {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dashboard",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li> */}
           {/*end::1 Engage*/}
        {/*begin::1 Industry*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/reports/profile", false)}`}
          aria-haspopup="true"
        > */}
          {/* <NavLink className="menu-link" to="/reports/profile">
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} /> */}
              {/* <AssignmentIndIcon /> */}
            {/* </span> */}
            {/* <span className="menu-text">Profil</span> */} 
          {/* </NavLink> */}
        {/* </li> */}
        <li
          className={`menu-item ${getMenuItemActive("/Dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Dashbord</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/category", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/category">
            <span className="svg-icon menu-icon">
              <CategoryIcon />
            </span>
            <span className="menu-text">Destinasjonsside</span>
          </NavLink>
        </li>

        {/*get plan details*/}
        <li
          className={`menu-item ${getMenuItemActive("/plans", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/plans">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Subscription</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/reports/users", false)}`}
          aria-haspopup="true"
        > */}
          {/* <NavLink className="menu-link" to="/reports/users">
            <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} /> */}
              {/* <Assignment/IndIcon /> */}
            {/* </span>
            <span className="menu-text">Contact us</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/chat", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/chat">
            <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              {/* <Assignment/IndIcon /> */}
            </span>
            <span className="menu-text">Chat</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/reports/userData", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reports/userData">
            <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
              {/* <AssignmentIndIcon /> */}
            </span>
            <span className="menu-text">User Data</span>
          </NavLink>
        </li>
       

        {/* <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/reports/users",
                    false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link" to="/reports/users">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Users</span>
                    <span className="menu-text">Users</span>
                  </NavLink>
                </li> */}

        {/*end::1 Engage*/}
        {/*  */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
