import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet, ApiDelete, ApiPut } from "../../../helpers/API/ApiData";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Modal } from "react-bootstrap";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Plan = () => {
  const [planList, setPlanList] = useState([]);
  const [handleForActive, setHandleForActive] = useState("");
  const [handleForUpdate, setHandleForUpdate] = useState({});
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const getPlanDetails = async () => {
    await ApiGet("plan/")
      .then((res) => {
        console.log("Plans", res?.data?.payload?.allPlan);
        setPlanList(res?.data?.payload?.allPlan);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePlanDetails = async () => {
    if (
      handleForUpdate.planName === "" ||
      handleForUpdate.validity === "" ||
      handleForUpdate.planCost === "" ||
      handleForUpdate.planDescription === ""
    ) {
      toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    await ApiPut(`plan/id=${handleForUpdate?._id}`, {
      planType: handleForUpdate?.planType,
      planDescription: handleForUpdate?.planDescription,
      planCost: handleForUpdate?.planCost,
      validity: handleForUpdate?.validity,
    })
      .then((res) => {
        setOpen(false);
        setHandleForUpdate({});
        getPlanDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPlanDetails();
  }, []);

  let i = 0;
  const columns = [
    {
      name: "S.nr",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },

    {
      name: "Plan Type",
      selector: "planType",
      sortable: true,
    },

    {
      name: "Plan Description",
      selector: "planDescription",
      sortable: true,
    },

    {
      name: "Plan Cost",
      selector: "planCost",
      sortable: true,
    },

    {
      name: "Validity in Days",
      selector: "validity",
      sortable: true,
    },

    {
      name: "Handlinger",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {/* <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  handleForActive(row._id);
                }}
              >
                <DeleteIcon />
              </div> */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  setHandleForUpdate(row);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];

  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  return (
    <>
      <div className="card p-5">
        <div className="p-4 mb-20">
          <div className="d-flex justify-content-between">
            <h1>Subscription Details</h1>
          </div>
          <DataTable
            noHeader="true"
            columns={columns}
            data={planList}
            customStyles={customStyles}
          />
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Varsling!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Er du sikker på at du vil slette disse dataene?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => Blockuser()}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={adddisable} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to disable vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => disableuser()}>
            disable
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={addactive} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to Active vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => active()}>
            active
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(!open);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="form ml-30 ">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {" "}
                Plan Type
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="planType"
                    value={handleForUpdate?.planType}
                    onChange={(e) =>
                      setHandleForUpdate({
                        ...handleForUpdate,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {" "}
                Plan Description
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="planDescription"
                    value={handleForUpdate?.planDescription}
                    onChange={(e) =>
                      setHandleForUpdate({
                        ...handleForUpdate,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {" "}
                Plan Cost
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="planCost"
                    value={handleForUpdate?.planCost}
                    onChange={(e) =>
                      setHandleForUpdate({
                        ...handleForUpdate,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {" "}
                Validity in Days
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="validity"
                    value={handleForUpdate?.validity}
                    onChange={(e) =>
                      setHandleForUpdate({
                        ...handleForUpdate,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={(e) => {
                  updatePlanDetails();
                }}
                className="btn btn-success mr-2"
              >
                Update
              </button>
            </div>
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default Plan;
