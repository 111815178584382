import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet } from "../../../helpers/API/ApiData";

const Users = () => {
  const [allUserData, setAllUserData] = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    await ApiGet("v1/user/")
      .then((res) => {
        console.log("User", res?.data?.payload?.allUser);
        setAllUserData(res?.data?.payload?.allUser);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },
    {
      name: "FirstName",
      cell: (row) => {
        return (
          <>
            {row?.firstName}&nbsp;{row?.lastName}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Contact",
      cell: (row) => {
        return (
          <>
            {row?.countryCode}&nbsp;{row?.phone}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Current Plan",
      selector: "currentPlanDetails[0]",
      cell: (row) => {
        return <>{row?.currentPlanDetails[0]?.planDescription}</>;
      },
      sortable: true,
    },

    {
      name: "Current Plan Start Date",
      selector: "currentPlanDetails[0]",
      cell: (row) => {
        return <>{row?.currentPlanDetails[0]?.startDate}</>;
      },
      sortable: true,
    },

    {
      name: "Current Plan End Date",
      selector: "currentPlanDetails[0]",
      cell: (row) => {
        return <>{row?.currentPlanDetails[0]?.endDate}</>;
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  return (
    <div>
      {" "}
      <DataTable
        columns={columns}
        data={allUserData}
        customStyles={customStyles}
      />
    </div>
  );
};

export default Users;
