import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet, ApiDelete, ApiPut } from "../../../helpers/API/ApiData";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Modal } from "react-bootstrap";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const UsersReports = () => {
  const classes = useStyles();
  const [userReport, setUserReport] = useState();
  const [submitValue, setSubmitValue] = useState(false);
  const [filteredReport, setFilteredReport] = useState();
  const [activeStatusId, setIsactiveStatusId] = useState();
  const [Isdisable, SetIsdisable] = useState();
  const [Isblock, setIsblock] = useState();
  const [show, setShow] = useState(false);
  const [aid, setaid] = useState();
  const [giddisable, setgiddisable] = useState();
  const [gid, setGid] = useState();
  const [addactive, setisaddactive] = useState();
  const [adddisable, setadddisable] = useState(false);
  const [genreName, setGenreName] = useState();
  const [errors] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClose1 = () => {
    setOpen(false);
  };

  const [rowDataMusic, setRowDataMusic] = useState();
  const [rowDataProduct, setRowDataProduct] = useState();
  const [musicProduct, setMusicOrProduct] = useState("music");

  const [block, SetIsblock] = useState();

  // useEffect store
  useEffect(() => {
    setIsactiveStatusId("active");
  }, [activeStatusId]);

  useEffect(() => {
    setIsblock("blocked");
  }, [Isblock]);

  useEffect(() => {
    SetIsdisable("disabled");
  }, [Isdisable]);

  //
  const finalUpdate = async () => {
    await ApiPut(`cms/contact/id=${gid}`, {
      response: genreName,
    })
      .then((res) => {
        setOpen(false);
        setGenreName(genreName);

        setDataChange([...dataChange, "Genre Updated"]);
        toast.success(res.data.message);

        setLoading(false);
        getUserReports();
        //console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const handleClose = () => {
    setShow(false);
    setadddisable(false);
    setisaddactive(false);
  };

  // useEffect(() => {
  //   console.log("disabled", gid);
  // }, [gid]);

  const handleShow = (id) => {
    setShow(true);
    setGid(id);
  };

  const handledisable = (id) => {
    setadddisable(true);
    setgiddisable(id);
  };

  const newactive = (id) => {
    setisaddactive(true);
    setaid(id);
  };

  const active = async () => {
    const data = {
      userId: aid,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiDelete(`cms/contact/id=${aid}`)
      .then((res) => {
        console.log("admin", res);

        // setallGenrels()
        getUserReports();
        setisaddactive(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const Blockuser = async (id) => {
    const data = {
      userId: gid,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiDelete(`cms/contact/id=${gid}`)
      .then((res) => {
        console.log("admin", res);

        // setallGenrels()
        getUserReports();
        setShow(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      });
  };

  const disableuser = async () => {
    const data = {
      userId: giddisable,
    };

    // console.log("dfdfd",data)

    console.log("datadata", data);

    await ApiGet(`admin/block?userId=${giddisable}&status=${Isdisable}`, data)
      .then((res) => {
        console.log("admin", res);

        // setallGenrels()
        getUserReports();
        setadddisable(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      });
  };
  const handleForUpdateGenre = async (data) => {
    setGenreName(data?.response);
    setOpen(true);
    setSubmitValue(true);
    console.log(data);
    // setGenreName(data.productName);

    console.log(
      "Product ID///////////////////////////////////",
      data.productName
    );
    setGid(data._id);
  };

  const handleForMore = async (music, product) => {
    setOpen(true);
    console.log(music);
    console.log(product);
    setRowDataMusic(music);
    setRowDataProduct(product);
  };

  useEffect(() => {
    getUserReports();
  }, []);

  const getUserReports = async () => {
    await ApiGet("cms/contact")
      .then((res) => {
        console.log("admin contact", res);
        setUserReport(res.data.payload);
        setFilteredReport(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleSearch = (e) => {
  //   console.log("eeeee", e.target.value);
  //   var value = e.target.value.toLowerCase();
  //   setFilteredReport(() =>
  //     userReport.filter(
  //       (item) =>
  //         item?.name.toLowerCase().includes(value) ||
  //         item?.email.toLowerCase().includes(value)
  //     )
  //   );
  // };

  let i = 0;
  const columns = [
    {
      name: "S.nr",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },

    {
      name: "Navn",
      selector: "name",
      sortable: true,
    },

    {
      name: "E-post",
      selector: "email",
      sortable: true,
    },

    {
      name: "Beskjed",
      selector: "message",
      sortable: true,
    },

    {
      name: "Respons",
      selector: "response",
      sortable: true,
    },

    {
      name: "Handlinger",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer mr-3"
                onClick={() => {
                  handleShow(row._id);
                }}
              >
                <DeleteIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  handleForUpdateGenre(row);
                }}
              >
                <CreateIcon />
              </div>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  return (
    <>
      <div className="card p-5">
        <div className="p-4 mb-20">
          <div className="d-flex justify-content-between">
            <h1>Kontakt oss-rapporter</h1>
          </div>
          <DataTable
            noHeader="true"
            columns={columns}
            data={filteredReport}
            customStyles={customStyles}
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Varsling!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Er du sikker på at du vil slette disse dataene?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => Blockuser()}>
            Slett
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={adddisable} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to disable vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => disableuser()}>
            disable
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={addactive} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to Active vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancle
          </Button>
          <Button variant="danger" onClick={() => active()}>
            active
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="form ml-30 ">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {" "}
                Respons
              </label>
              <div className="col-lg-9 col-xl-6">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="response"
                    value={genreName && genreName}
                    onChange={(e) => setGenreName(e.target.value)}
                  />
                </div>
                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "12px",
                  }}
                >
                  {errors["title"]}
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              {submitValue && submitValue === true ? (
                <button
                  onClick={(e) => {
                    finalUpdate();
                  }}
                  className="btn btn-success mr-2"
                >
                  <span>Add Response</span>
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default UsersReports;
