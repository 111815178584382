import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet } from "../../../helpers/API/ApiData";

const Artists = () => {
  const [allArtistData, setAllArtistData] = useState([]);

  useEffect(() => {
    getArtistData();
  }, []);

  const getArtistData = async () => {
    await ApiGet("admin/getAllArtists")
      .then((res) => {
        console.log(res.data.data);
        setAllArtistData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "FirstName",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "LastName",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "Role",
      selector: "role",
      sortable: true,
    },
    {
      name: "registered",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              {row.isRegistered === true ? "True" : "false"}
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  return (
    <div>
      {" "}
      <DataTable
        columns={columns}
        data={allArtistData}
        customStyles={customStyles}
      />
    </div>
  );
};

export default Artists;
