import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { ApiGet } from "../../../helpers/API/ApiData";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ArtistsReports = () => {
  const [artistReport, setArtistReport] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClose1 = () => {
    setOpen(false);
  };

  const [rowDataMusic, setRowDataMusic] = useState();
  const [rowDataProduct, setRowDataProduct] = useState();
  const [musicProduct, setMusicOrProduct] = useState("music");

  const handleForMore = async (music, product) => {
    setOpen(true);
    console.log(music);
    console.log(product);
    setRowDataMusic(music);
    setRowDataProduct(product);
  };

  useEffect(() => {
    const getUserReports = async () => {
      await ApiGet("admin/ArtistReport")
        .then((res) => {
          setArtistReport(res.data.data);
          setFilteredReport(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUserReports();
  }, []);
  let i = 0;
  const columns = [
    {
      name: "SNo",
      cell: (row) => {
        i = i + 1;
        return <>{i}</>;
      },
      sortable: true,
      width: "70px",
    },
    {
      name: "firstName",
      selector: "firstName",
      sortable: true,
    },
    {
      name: "lastName",
      selector: "lastName",
      sortable: true,
    },
    {
      name: "email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Total Music",
      cell: (row) => {
        return <>{row.music.length}</>;
      },
      sortable: true,
    },
    {
      name: "Total Music Earning $",
      cell: (row) => {
        return <>{row?.musicEarning ? row?.musicEarning.totalEarning : "--"}</>;
      },
      sortable: true,
    },
    {
      name: "Total Product",
      cell: (row) => {
        return <>{row.product.length}</>;
      },
      sortable: true,
    },
    {
      name: "Total Product Earning $",
      cell: (row) => {
        return (
          <>{row?.productEarning ? row?.productEarning.totalEarning : "--"}</>
        );
      },
      sortable: true,
    },

    {
      name: "Show More",
      cell: (row, key) => {
        return (
          <>
            {row.music.length === 0 && row.product.length === 0 ? (
              <div>--</div>
            ) : (
              <div className="d-flex justify-content-between">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleForMore(row.music, row.product);
                  }}
                >
                  <Tooltip title="Show More" arrow>
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
              </div>
            )}
          </>
        );
      },
      sortable: true,
    },
  ];
  //* Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleSearch = (e) => {
    console.log("eeeee", e.target.value);
    var value = e.target.value.toLowerCase();

    setFilteredReport(() =>
      artistReport.filter(
        (item) =>
          item?.firstName.toLowerCase().includes(value) ||
          item?.lastName.toLowerCase().includes(value)
      )
    );
  };
  // console.log("artistReporty", artistReport);

  return (
    <>
      <div className="card p-5">
        <div className="p-4 ">
          <div className="d-flex justify-content-between">
            <h1>Artist Reports</h1>
          </div>
          <div className="form my-5">
            <div className="form-group d-flex align-items-center">
              {/* <label className="col-form-label mr-5">Filter by name:</label> */}
              <div className="">
                <div>
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid `}
                    name="title"
                    placeholder="Search Artists"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <DataTable
            noHeader="true"
            columns={columns}
            data={filteredReport}
            customStyles={customStyles}
          />
        </div>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose1}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose1}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>

        <List>
          <div className="d-flex justify-content-center mb-10">
            <div className="mr-5">
              <Button
                className="bg-primary"
                onClick={() => {
                  setMusicOrProduct("music");
                }}
              >
                Musics
              </Button>
            </div>
            <div className="ml-5">
              {" "}
              <Button
                className="bg-primary"
                onClick={() => {
                  setMusicOrProduct("product");
                }}
              >
                Products
              </Button>
            </div>
          </div>
          <div className="row d-flex mt-5 justify-content-around m-8 bg-white">
            {musicProduct === "music" ? (
              <>
                {rowDataMusic && rowDataMusic.length === 0 ? (
                  <div className="p-10 d-flex justify-content-center my-10 font-weigth-bold">
                    <h1>this artist not have a any music!!</h1>
                  </div>
                ) : (
                  rowDataMusic?.map((data, key) => {
                    return (
                      <>
                        <div className="col-md-4 p-5 " key={key}>
                          <div className="card body pt-5 pl-8 pr-8 pb-4 shadow p-3 mb-5 bg-white rounded">
                            <div className="row row-cols-2 m-1 h3">
                              <strong>Music : {++key}</strong>
                              <div></div>
                            </div>
                            <div className="text-center pb-5">
                              <img
                                src={
                                  data?.musicImage
                                    ? data?.musicImage
                                    : "../media/Media-library1.svg"
                                }
                                alt="music image"
                                style={{
                                  height: "140px",
                                  width: "140px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div>
                              <div className="row row-cols-2 m-1">
                                <strong>Music Title</strong>
                                <div className="text-left">
                                  {data.musicTitle}
                                </div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Music Description</strong>
                                <div>{data.musicDesc}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Is Paid</strong>
                                <div>{data.isPaid ? "Paid" : "free"}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Music Amount</strong>
                                <div>{data.amount}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Music Views</strong>
                                <div>{data.views}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Music Purchases</strong>
                                <div>{data.purchases}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })
                )}
              </>
            ) : (
              <>
                {rowDataProduct && rowDataProduct.length === 0 ? (
                  <div className="p-10 d-flex justify-content-center my-10 font-weigth-bold">
                    <h1>this artist not have a any product!!</h1>
                  </div>
                ) : (
                  rowDataProduct?.map((data, key) => {
                    return (
                      <>
                        <div className="col-md-4 p-5 " key={key}>
                          <div className="card body pt-5 pl-8 pr-8 pb-4 shadow p-3 mb-5 bg-white rounded">
                            <div className="row row-cols-2 m-1 h3">
                              <strong>Product : {++key}</strong>
                              <div></div>
                            </div>
                            <div className="text-center pb-5">
                              <img
                                src={
                                  data?.image
                                    ? data?.image
                                    : "../media/Media-library1.svg"
                                }
                                alt="product image"
                                style={{
                                  height: "140px",
                                  width: "140px",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <div>
                              <div className="row row-cols-2 m-1">
                                <strong>Product Title</strong>
                                <div className="text-left">
                                  {data?.title}
                                </div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Product Description</strong>
                                <div>{data?.description}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Product Type</strong>
                                <div>{data?.type}</div>
                              </div>
                              <div className="row row-cols-2 m-1">
                                <strong>Product Price</strong>
                                <div>{data?.price}</div>
                              </div>
                              {/* <div className="row row-cols-2 m-1">
                                <strong>Product Quantity</strong>
                                <div>{data?.quantity}</div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })
                )}
              </>
            )}
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default ArtistsReports;
